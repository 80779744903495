import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  users: [],
  regions: [],
  events: undefined
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setRegions: (state, action) => {
      const regionlist = action.payload.map(r => r.attributes);
      const regions = [];

      regionlist.forEach(region => {
        let lr = regions.find(r => r.label === region.area);
        if (!lr) {
          lr = {label: region.area, values: []};
          regions.push(lr);
        }

        lr.values.push({label: region.label, value: region.value});
      });
      state.regions = regions;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setUser,
  setUsers,
  setEvents,
  setRegions,
} = appSlice.actions

export default appSlice.reducer