import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { string, object, array } from 'yup';
import { deleteUser, loadRegions, loadUsers, registerUser } from "../lib/api";
import { setRegions, setUsers } from "../lib/appSlice";
import { useAuth } from "../lib/useAuth";
import { useLocalStorage } from "../lib/useLocalStorage";

function Users() {
    const [alert, setAlert] = useState();
    const [showForm, setShowForm] = useState(false);
    const { user:currentUser } = useAuth();
    const navigate = useNavigate();
    const [jwt] = useLocalStorage("jwt", null);
    const userList = useSelector((state) => state.app.users);
    const regionList = useSelector((state) => state.app.regions);
    const dispatch = useDispatch();

    if (currentUser.type !== "Administrator") {
        navigate('/dashboard');
    }

    const asyncLoadUsers = async () => {
        const userdata = await loadUsers(jwt);
        dispatch(setUsers(userdata.data));
    }
    const asyncLoadRegions = async () => {
        const result = await loadRegions(jwt);
        dispatch(setRegions(result.data.data));
    }
    const onDeleteUser = async (userId) => {
        const confirm = window.confirm("Are you sure?");
        if (confirm) {
            await deleteUser(userId, jwt);
            asyncLoadUsers();
        }
    }

    useEffect(() => {
        asyncLoadUsers();
        asyncLoadRegions();
    }, []); // eslint-disable-line

    return (
        <>
            <div className="flex justify-end pb-10">
                <button onClick={() => setShowForm(true)} className="bg-assa-blue text-white p-2 border-2 border-assa-blue hover:bg-white hover:text-assa-blue rounded">Add User</button>
            </div>
            {showForm &&
            <div>
                <div className="bg-gray-300 p-10 relative">
                    <div className="absolute top-5 right-10">
                        <button onClick={() => setShowForm(false)}>X</button>
                    </div>
                {alert && (
                    <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }} className="mb-2 p-2 text-white">
                        <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
                    </div>
                )}
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            type: '',
                            region: [],
                        }}
                        validationSchema={object().shape({
                            firstName: string().required("First Name is required"),
                            lastName: string().required("Last Name is required"),
                            email: string().email().required("Email is required"),
                            type: string().required("Type is required"),
                            region: array().when('type', {
                                is: "Sales",
                                then: array().of(string()).min(1, "At least one region must be specified"),
                                otherwise: array().notRequired(),
                              })
                        })}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setAlert();
                            const userData = {
                                ...values,
                                region: values.region.join(',')
                            };

                            console.log('values', values, userData);
                            try{
                                const response = await registerUser(userData, jwt);
                                console.log('response values', response);
                                setAlert(['success', "User Created. Invitation Sent."]);
                                resetForm();
                                asyncLoadUsers();
                            } catch (e) {
                                console.log('registration error', );
                                setAlert(['alert', e.response.data.error.message]);
                            }

                            setSubmitting(false);
                        }}
                    >
                        {({
                            values,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="font-black text-xl">New User Form</div>
                                <div className="grid md:grid-cols-2 gap-5">
                                    <div className="">
                                        <Field type="text" name="firstName" placeholder="First Name" className="border rounded p-2 rounded drop-shadow-input w-full" />
                                        <ErrorMessage name="firstName" component="div" />
                                    </div>
                                    <div className="">
                                        <Field type="text" name="lastName" placeholder="Last Name" className="border rounded  p-2 rounded drop-shadow-input w-full" />
                                        <ErrorMessage name="lastName" component="div" />
                                    </div>
                                </div>
                                <div className="pt-5 ">
                                    <Field type="email" name="email" placeholder="Email Address" className="border rounded  p-2 rounded drop-shadow-input w-full" />
                                    <ErrorMessage name="email" component="div" />
                                </div>
                                <div className="pt-5 ">
                                    <Field as="select" name="type" placeholder="Account Type" className="border rounded p-2 rounded drop-shadow-input w-full">
                                        <option value="" disabled >Please Select a Role</option>
                                        <option >Administrator</option>
                                        <option >Analyst</option>
                                        <option >Sales</option>
                                    </Field>
                                    <ErrorMessage name="type" component="div" />
                                </div>
                                <div className="pt-5 ">
                                    {values.type === "Sales" && (
                                        <>
                                            <Field as="select" multiple name="region" className="border rounded p-2 rounded drop-shadow-input w-full">
                                                <option value="" disabled >Please Select a Region</option>
                                                {regionList && regionList.map((region) => (
                                                    <optgroup key={region.label} label={region.label}>
                                                        {region.values.map((value) => (
                                                        <option key={value.value} value={value.value}>{value.label}</option>
                                                        ))}
                                                    </optgroup>
                                                ))}

                                            </Field>
                                            <ErrorMessage name="region" component="div" />
                                        </>
                                    )}
                                </div>
                                <button type="submit" disabled={isSubmitting} className="text-white bg-assa-blue border-2 border-assa-blue p-2 rounded mt-5 hover:text-assa-blue hover:bg-white">Add User &gt;</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            }
            <div className="uppercase font-bold pt-10">
                User List
            </div>
            <div className="">
                {userList.map((u) => {
                    return (
                        <div className="border-b border-gray-300 p-10 grid grid-cols-2" key={u.id}>
                            <div>
                                <div className="text-xl text-assa-blue font-bold">{u.firstName} {u.lastName}</div>
                                {u.confirmed === false &&<span className="text-red-500">Unconfirmed</span>}
                                <div className="text-sm">{u.email}</div>
                                <div className="text-sm">{u.type}{u.type === "Sales" && <span> - {u.region.replace(',',', ')}</span>}</div>
                            </div>
                            <div className="flex justify-end gap-5">
                                {/* <button className="text-assa-blue underline">Edit User</button> */}
                                <div>
                                    <button disabled={currentUser.id === u.id} onClick={()=>onDeleteUser(u.id)} className="bg-assa-blue text-white p-2 border-2 border-assa-blue hover:bg-white hover:text-assa-blue rounded disabled:bg-gray-500">Delete &gt;</button>
                                </div>
                            </div>
                        </div>
                    )

                })}

            </div>
        </>
    );
}

export default Users;