import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

const config = {
  headers:{
    'Content-Type': 'application/json',
  }
};
const auth = (jwt) => {
  return {headers: {...config.headers, 'Authorization': 'Bearer ' + jwt}};
}

// Authentication Required
// &filters[$or][0][date][$eq]=2020-01-01
// &filters[$or][1][date][$eq]=2020-01-02
const loadEvents = async (region, startDate, endDate, jwt) => {
  const nED = new Date(endDate.getTime()+1000*60*60*24);
  let base_url = `/api/events?sort=start_date:desc&pagination[pageSize]=1000`;
  if ( region ) {
    region.split(',').forEach((r,i) => {
      base_url += `&filters[$or][${i}][region][$contains]=${r}`
    })
  }
  if ( startDate ) {
    base_url += `&filters[start_date][$gte]=${new Date(startDate).toISOString().split('T')[0]}`
  }
  if ( endDate ) {
    base_url += `&filters[start_date][$lte]=${nED.toISOString().split('T')[0]}`
  }

  return await axios.get(API_URL+base_url, auth(jwt))
};
const loadRegions = async (jwt) => {
  const config = auth(jwt);
  return await axios.get(API_URL+'/api/regions?sort[0]=area&sort[1]=value', config)
};
const loadUsers = async (jwt) => {
  const config = auth(jwt);
  return await axios.get(API_URL+'/api/users', config)
};
const deleteUser = async (userId, jwt) => {
  const config = auth(jwt);
  return await axios.delete(API_URL+'/api/users/'+userId, config)
};
const registerUser = async (data, jwt) => {
  return await axios.post(API_URL+'/api/auth/local/register', {...data, username: data.email, password: new Date()}, auth(jwt));
}
const generateDownload = (filename, blob) => {

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `visitors_${filename}.csv`);
  document.body.appendChild(link);
  link.click();
}
const downloadVisitorData = async (eventId, jwt) => {
  const cfg = {...auth(jwt), responseType: 'blob'};
  const response = await axios.get(`${API_URL}/api/visitors/event/${eventId}`, cfg);
  generateDownload(eventId, response.data);
  return;
}

const downloadEventsVisitorData = async (eventIds, jwt) => {
  const cfg = {...auth(jwt), responseType: 'blob'};
  const response = await axios.post(`${API_URL}/api/visitors/events`, eventIds, cfg);
  generateDownload(eventIds.join('-'), response.data);
  return;
}

// No Auth Required
const sendResetPassword = async (data) => {
    return await axios.post(API_URL+'/api/auth/reset-password', data, config);
}
const sendForgotPassword = async (data) => {
    return await axios.post(API_URL+'/api/auth/forgot-password', data, config);
}
const loginUser = async (data) => {
  return await axios.post(API_URL+'/api/auth/local', {identifier: data.email, password: data.password}, config);
}

export {
  loadEvents,
  loadUsers,
  sendForgotPassword,
  sendResetPassword,
  registerUser,
  loginUser,
  downloadVisitorData,
  deleteUser,
  downloadEventsVisitorData,
  loadRegions,
};