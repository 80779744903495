import { Route, Routes } from "react-router-dom";
import { AuthLayout } from "./components/AuthLayout";
import { ProtectedLayout } from "./components/ProtectedLayout";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/forgot-password";
import Leads from "./pages/leads";
import Login from "./pages/login";
import ResetPassword from "./pages/reset-password";
import Users from "./pages/users";

function App() {
  return (
    <div className="app">
      <Routes >
        <Route element={<AuthLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="leads" element={<Leads />} />
            <Route path="users" element={<Users />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
