import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { downloadEventsVisitorData, downloadVisitorData, loadEvents } from "../lib/api";
import { setEvents } from "../lib/appSlice";
import { useAuth } from "../lib/useAuth";
import { useLocalStorage } from "../lib/useLocalStorage";

function Leads() {
    const [jwt] = useLocalStorage("jwt", null);
    const { user:currentUser } = useAuth();
    const eventList = useSelector((state) => state.app.events);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const dispatch = useDispatch();

    const asyncLoadEvents = async () => {
        const response = await loadEvents(currentUser.region, startDate, endDate, jwt);
        dispatch(setEvents(response.data.data));
    }
    const onDownloadAllLeads = () => {
        const ids = eventList.map((e) => e.id);
        downloadEventsVisitorData(ids, jwt);
    }
    const onSearchClick = (e) => {
        e.preventDefault();
        asyncLoadEvents();
    }
    // useEffect(() => {
    //     asyncLoadEvents();
    // }, []); // eslint-disable-line

    return (
        <div>
            <div className="bg-gray-300 p-10 relative">
                <div className="text-xl font-black text-assa-blue">Event Search</div>
                <div className="pb-5">Search for events by start date and end date</div>
                <div className="md:flex justify-between">
                    <div className="md:grid grid-cols-2 gap-5">
                        <div className="flex flex-col">
                            <label htmlFor="start_date">Start Date</label>
                            <ReactDatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                maxDate={new Date()}
                                placeholderText="Select a Start Date"
                                />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="end_date">End Date</label>
                            <ReactDatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                maxDate={new Date()}
                                placeholderText="Select an End Date"
                                />
                        </div>
                    </div>
                    <button onClick={onSearchClick} className="bg-assa-blue text-white p-2 border-2 border-assa-blue hover:bg-white hover:text-assa-blue rounded">Search &gt;</button>
                </div>
            </div>
            {eventList !== undefined &&
            <>
                <div className="uppercase font-bold pt-10 flex justify-between">
                    <span>Events</span>
                    <button onClick={onDownloadAllLeads} className="text-assa-blue">Download All Leads</button>
                </div>
                <div className="">
                    {eventList.length === 0 && <div>No Events Match Your Criteria</div>}
                    {eventList.map((e) => {
                        return (
                            <div className="border-b border-gray-300 md:p-10 grid md:grid-cols-2" key={e.id}>
                                <div>
                                    <div className="text-xl text-assa-blue font-bold" title={e.id}>{e.attributes.Name}</div>
                                    <div className="text-sm">
                                        {new Date(e.attributes.start_date).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}
                                        &nbsp;|&nbsp;
                                        {new Date(e.attributes.start_date).toLocaleTimeString('en-us', { hour: "numeric", minute: "2-digit", hour12: true})}
                                        &nbsp;-&nbsp;
                                        {new Date(e.attributes.end_date).toLocaleTimeString('en-us', { hour: "numeric", minute: "2-digit", hour12: true})}
                                    </div>
                                    {!currentUser.region && 
                                    <div className="text-sm">
                                        {e.attributes.region}
                                    </div>
                                    }
                                </div>
                                <div className="flex justify-end gap-5">
                                    <button onClick={() => downloadVisitorData(e.id, jwt)} className="bg-assa-blue text-white p-2 border-2 border-assa-blue hover:bg-white hover:text-assa-blue rounded">
                                        Download Leads &gt;
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>}
        </div>
    );
}

export default Leads;