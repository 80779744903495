import { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sendResetPassword } from '../lib/api';


const ResetPassword = () => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState();
    const [params] = useSearchParams();
    const code = params.get('code');

    const initialValues = {
        password: "",
        passwordConfirmation: "",
    }

    const validationSchema = Yup.object({
        password: Yup.string().required("Required"),
        passwordConfirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Required"),
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        setAlert();

        values.code = code;

        try {
            await sendResetPassword(values);
            const message = `Your password has been resetted. In a few second you'll be redirected to login page.`;
            setAlert(['success', message]);
            resetForm();
            navigate('/');
        } catch (error) {
            if (!error.response.data.message) {
                setAlert(['alert', "Something went wrong"])
            } else {
                const messages = error.response.data.message[0].messages;

                const list = [];
                messages.forEach((message, i) => {
                    let item = "";
                    if (i === 0) item += `<ul>`;

                    item += `<li>${message.id}</li>`;

                    if (i === messages.length - 1) item += `</ul>`
                    list.push(item);
                });

                setAlert(['alert', list]);
            }
        }
        setSubmitting(false);
    }

    return (
        <div className="flex flex-col content-center items-center justify-center h-full w-full">

            <div className="text-5xl uppercase text-white tracking-tighter">Assa Abloy</div>
            <div className="bg-white rounded mt-5 p-10">
                {alert && (
                    <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }}>
                        <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
                    </div>
                )}
                <br />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm })} >
                    {({ isSubmitting, isValid }) => (
                        <Form>
                            <div>
                                <div><label htmlFor="password">Password</label></div>
                                <Field type="password" id="password" name="password" placeholder="Password" className="border rounded w-96 p-2" />
                                <div className="text-red-500"><ErrorMessage name="password" /></div>
                            </div>

                            <br />

                            <div>
                                <div><label htmlFor="passwordConfirmation">Repeat Password</label></div>
                                <Field type="password" id="passwordConfirmation" name="passwordConfirmation" placeholder="Repeat password" className="border rounded w-96 p-2" />
                                <div className="text-red-500"><ErrorMessage name="passwordConfirmation" /></div>
                            </div>

                            <br />

                            <button className="text-white bg-assa-blue border-2 border-assa-blue p-2 rounded mt-5 hover:text-assa-blue hover:bg-white"
                                type="submit"
                                disabled={!isValid} >
                                {!isSubmitting && "Reset password"}
                                {isSubmitting && "Loading..."}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ResetPassword;