import { ErrorMessage, Field, Formik } from "formik";
import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { loginUser } from "../lib/api";
import { useAuth } from "../lib/useAuth";
import { useLocalStorage } from "../lib/useLocalStorage";


function Login() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState();
  const { user, login } = useAuth();
  const [email, setEmail] = useLocalStorage("email", null);
  const [,setJWT] = useLocalStorage("jwt", null);

  if (user) {
    return <Navigate to="/dashboard/" />;
  }

  return (
    <div className="flex flex-col content-center items-center justify-center h-full w-full">

      <div className=""><img src="images/assa-white-logo.png" alt="assa abloy logo" /></div>
      <div className="bg-white rounded mt-5 p-10">
        {alert && (
            <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }} className="mb-2 p-2 text-white">
                <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
            </div>
        )}
        <Formik
          initialValues={{ email: email, password: '', remember: true }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setEmail(values.email);

            try {
              const data = await loginUser(values)
              console.log('logged in', data.data);
              login(data.data.user);
              setJWT(data.data.jwt);
              navigate('/dashboard/');
            } catch(e) {
              // window.alert('Login Failed');
              console.log('login error', e);
              setAlert(['alert', e.response.data.error.message]);
              setSubmitting(false);
            }
            
          }}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="pt-5 flex flex-col">
                <label htmlFor="email" className="uppercase">Email Address</label>
                <Field type="email" name="email" required={true} className="border rounded w-96 p-2" />
                <ErrorMessage name="email" component="div" />
              </div>
              <div className="pt-5 flex flex-col">
                <label htmlFor="password" className="uppercase">Password</label>
                <Field type="password" name="password" required={true} className="border rounded w-96 p-2" />
                <ErrorMessage name="password" component="div" />
              </div>
              <div className="flex justify-between">
                <div className="">
                  <Field type="checkbox" name="remember" /> <label htmlFor="remember">Remember Me</label>
                </div>
                <div className="text-assa-blue underline">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </div>
              <button type="submit" disabled={isSubmitting} className="text-white bg-assa-blue border-2 border-assa-blue p-2 rounded mt-5 hover:text-assa-blue hover:bg-white">Sign in &gt;</button>
            </form>
          )}
        </Formik>
      </div>

    </div>
  );
}

export default Login;
