import { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { sendForgotPassword } from '../lib/api';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [alert, setAlert] = useState();

    const initialValues = {
        email: ""
    }

    const validationSchema = Yup.object({
        email: Yup.string().email("Insert a valid email").required("Required")
    });

    const onSubmit = async (values, { setSubmitting, resetForm }) => {
        setAlert();

        try {
            await sendForgotPassword(values);

            const message = `Please check your email to reset your password.`;
            setAlert(['success', message]);

            resetForm();
        } catch (error) {
            if (!error.response.data.message) {
                setAlert(['alert', "Something went wrong"])
            } else {
                const messages = error.response.data.message[0].messages;

                const list = [];
                messages.forEach((message, i) => {
                    let item = "";
                    if (i === 0) item += `<ul>`;

                    item += `<li>${message.id}</li>`;

                    if (i === messages.length - 1) item += `</ul>`
                    list.push(item);
                });

                setAlert(['alert', list]);
            }
        };
        setSubmitting(false);
    }

return (
    <div className="flex flex-col content-center items-center justify-center h-full w-full">

        <div className="text-5xl uppercase text-white tracking-tighter">Assa Abloy</div>
        <div className="bg-white rounded mt-5 p-10">
            {alert && (
                <div style={{ backgroundColor: alert[0] === "success" ? "lightgreen" : "lightcoral" }}>
                    <div dangerouslySetInnerHTML={{ __html: alert[1] }} />
                </div>
            )}
            <br />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, { setSubmitting, resetForm })} >
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <div>
                            <div><label htmlFor="email">Email</label></div>
                            <Field type="email" id="email" name="email" placeholder="Email" className="border rounded w-96 p-2" />
                            <div className="text-red-500"><ErrorMessage name="email" /></div>
                        </div>

                        <br />
                        <div className='flex justify-between mt-5'>
                            <button className="text-white bg-assa-blue border-2 border-assa-blue p-2 rounded hover:text-assa-blue hover:bg-white"
                                type="submit"
                                disabled={!isValid} >
                                {!isSubmitting && "Send Reset Password link"}
                                {isSubmitting && "Loading..."}
                            </button>
                            <Link to="/" className='text-assa-blue underline'>Back to Login</Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
)
}

export default ForgotPassword;