import { Link, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../lib/useAuth";

export const ProtectedLayout = () => {
  const { user, logout } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-white border-b-2 border-gray">
        <div className="container mx-auto px-5 md:px-0 py-5">
          <div className="md:flex justify-between">
            <Link to="/"><img src="/images/assa-blue-logo.png" alt="assa abloy logo" /></Link>
            <div className="flex gap-5 content-center items-center justify-center">
              Hi, {user.firstName}
              <Link className="underline text-assa-blue" to="/dashboard/leads">Leads</Link>
              {user.type === "Administrator" &&
                <Link className="underline text-assa-blue" to="/dashboard/users">Users</Link>
              }
              <button className="uppercase underline text-assa-blue" onClick={() => logout()}>Logout</button>
            </div>
          </div>
        </div>
      </nav>
      <div className="bg-white flex-grow">
        <div className="container mx-auto px-5 md:px-0 mt-5">
          <Outlet />
        </div>
      </div>
    </div>
  )
};